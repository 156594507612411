/* html,
body,
.main {
  min-height: 100vh;
  min-width: 400px;
  font-family: 'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
} */

body,
.main {
  /* background-color: lightblue; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  /* min-height: 100vh; */
}

/* main{
  margin-right: 20px;
  margin-left: 20px;
  background-color: white; */
/* height: 100vh; */

/* } */
/* .content {
  flex: 1 0 auto;
} */
/* h1 {
    text-align: center;
    color: cornflowerblue;
  } */
nav {
  /* background-color: rgba(66, 42, 11,0.7); */
  /* background-image: linear-gradient(to bottom,100% rgba(66,42,11,1), 0% rgba(66, 42, 11, 0)); */
  background-image: linear-gradient(to bottom,rgba(66, 42, 11, 1), rgba(255, 255, 255, 0.1) 80%, rgba(255, 255, 255, 0) 100%);
  /* background: linear-gradient(180deg,0% rgba(0,0,0,1),100% rgba(0, 0, 0, 0),); */
  /* box-shadow: 0px 0px 10px -5px rgba(0, 0, 0 ); */
  /* padding: 10px; */
  position: sticky;
  top: 0;
  /* z-index: 99; */
}

/* h2 {
  color: lightblue;
} */

nav ul {
  list-style-type: none;
  margin: 0;
}

nav li {
  display: inline;
  margin-right: 25px;
}

nav a {
  color: white;
  font-size: 1.5em;
  text-decoration: none;
}

nav a:hover {
  /* color: pink; */
  color: white;
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: row;
  position: sticky;
  bottom: 0;
  height: 50px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 80% ); */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}

.footer a {
  justify-content: space-evenly;
  color: #fff;
}
.rot-180:hover {
  animation-name: rot-180;
  transition: ease-in-out;
  animation-duration: 200ms;
  animation-iteration-count: 1;
}

@keyframes rot-180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}